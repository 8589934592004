// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kategorie-fuer-den-mensch-tsx": () => import("./../../../src/pages/Kategorie/FuerDenMensch.tsx" /* webpackChunkName: "component---src-pages-kategorie-fuer-den-mensch-tsx" */),
  "component---src-pages-kategorie-fuer-hunde-tsx": () => import("./../../../src/pages/Kategorie/FuerHunde.tsx" /* webpackChunkName: "component---src-pages-kategorie-fuer-hunde-tsx" */),
  "component---src-pages-kategorie-fuer-katzen-tsx": () => import("./../../../src/pages/Kategorie/FuerKatzen.tsx" /* webpackChunkName: "component---src-pages-kategorie-fuer-katzen-tsx" */),
  "component---src-template-product-tsx": () => import("./../../../src/template/product.tsx" /* webpackChunkName: "component---src-template-product-tsx" */)
}

